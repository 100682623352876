.image-src-second {
    max-height: 10em;
}
.header-banner {
    background: url("../../public/assets/img/backdrop.png") no-repeat top center;
    background-size: auto 100%;
    min-height: 500px;
}

.header-banner strong {
    display: block;
}

.mobile .header-banner {
    background-position: 48% 0;
    min-height: 250px !important;
}

.productsView {
    margin-top: -2em;
    margin-bottom: 2em;
}

.mobile .productsView {
    margin: 2em;
}

.mobile .productInfo {
    display: flex;
    flex-direction: row;
}

.mobile .productInfo>div.itemImage {
    width: 150px;
}
.mobile .productInfo>div.itemInfo {
    padding-left: 1em;
    text-align: left;
    align-content: center;
}

.productInfo div.itemInfo h5 {
    text-transform: uppercase;
}