@tailwind base;
@tailwind components;
@tailwind utilities;

.main-container {
    background-color: #e3e2de;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}