.img-home {
    max-height: 3em;
}

.shake {
    animation: shakeCart 0.4s ease-in-out forwards;
  }
  
  @keyframes xAxis {
    100% {
      transform: translateX(calc(100vw - 150px));
    }
  }
  
  @keyframes yAxis {
    100% {
      transform: translateY(-104px);
    }
  }
  
  @keyframes shakeCart {
    25% {
      transform: translateX(6px);
    }
    50% {
      transform: translateX(-4px);
    }
    75% {
      transform: translateX(2px);
    }
    100% {
      transform: translateX(0);
    }
  }
  