body {
  font-family: Poppins;
}



.montserrat, .montserrat * {
  font-family: Poppins !important;
}
.noto-serif, .noto-serif * {
  font-family: "Noto Serif Display", serif !important;
}

h1, h2, h3, h4, h5, h6, p, button {
  font-family: Poppins !important;
}