.text-underline {
    text-decoration: underline;
    margin-top: 20px;
    margin-bottom: 10px;
}

.text-wrapper {
    ul {
        list-style-type: square;
        text-align: justify;
    }
}